html, body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-old-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-loader {  /* https://stackoverflow.com/a/49365417 */
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 42px / 2) ); /* spinner height is 42px */
  right: calc( 50% - ( 42px / 2) ); /* spinner width is 42px */
}

.right-aligned-btn-container {
  text-align: right;
}

@media only screen and (max-width: 992px) {
  .align-center-md {
    text-align: center;
  }

  .col-padding-md{
    padding: 10px
  }
}

pre {
  font-family: sans-serif
}

@media only screen and (max-width: 576px) {
	.right-aligned-btn-container {
    margin-top: 15px;
    text-align: center;
  }	
  
  .align-center-xs {
    text-align: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  min-height: calc(100vh - 56px);
  padding-bottom: 50px;
  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  
}

.footer {
  height: 50px;
}
