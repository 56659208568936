/*
Attempting to synchronize changes with reactstrap using these pixel sizes found at
https://getbootstrap.com/docs/5.1/layout/grid/ and https://react-bootstrap.github.io/layout/grid/:
xs <576px
sm ≥576px
md ≥768px
lg ≥992px
xl ≥1200px
xxl ≥1400px
*/

@media only screen and (min-width: 576px) {
  .order-info-container {
    /* font-size: large; */
    text-align: center;
    line-height: 1;
  }
}



.order-info-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

@media only screen and (min-width: 576px) {
  .order-info-row {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
